:root{
  --colorA: #d39e00;
}

html {
  scroll-behavior: smooth;
}

section {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(84vh - 100px);
  color: white;
}

section {
  padding: 50px;
  position: relative;
  color: #2b2928;
}

a {
  color: #d39e00 !important;
}

#root {
  margin: 0px !important;
  padding: 0px !important;
  background-color: whitesmoke !important;
  color: #6c757d !important;
}

#banner {
  color: white;
  top: 0;
  height: 500px;
  padding: 0px !important;
  margin: 0px !important;
  background-color: var(--colorA);
}

#banner h1 {
  margin-top: 250px;
  font-size: 4.5rem !important;
  text-shadow: 2px 1px black;
}

#banner button {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.35)!important;
  border-color: #343a4000 !important;
}

#banner button:hover {
  background-color: rgba(0, 0, 0, 0.70)!important;
}

#brands {
  text-align: center;
}
#brands img {
  max-height: 100px !important;
  max-width: 370px !important;
  text-align: center;
  margin: 0 auto;
}


.shadow
{
  position: relative;
}
.shadow:before, .effect2:after
{
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width:300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.shadow:after
{
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

.bee-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
}

.list-links ul, .list-social ul {
  list-style: none;
  padding-left: 14px;
}

ul.menu {
  list-style: none;
  padding: 0;
  font-size: 19px;
  line-height: 28px;
}


